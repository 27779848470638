<template>
    <div id="WordReexercise" class="bg-success-soft h-100"> 
    <Header/>    
    <CommonLeftRightAlertFunc ref="commonLeftRightAlertFunc" :onfuncleft="onExitLeft" :onfuncright="onExitRight" :title="title" :content="content" :btnnameleft="btnNameLeft" :btnnameright="btnNameRight"/>    
             
<body class=" bg-success-soft" >
<!-- header -->
    <nav class="navbar bg-success-soft fixed-top nicole-noshadow">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
        </a> 
      </div>
    </nav>      
<!-- End header -->
    <div class="nicolespacetop"></div>
        <transition name="fade">
      <div class="container  mt-md-3  pb-4 pb-md-5" v-if="isShow">
      <div class="card p-0 choose_vh green-shadow"> 
      <div class=" py-2 px-3"> 
          <div class="text-center mt-3">  
            <!-- eslint-disable vue/no-v-html -->
            <div :class="(currExam.name.length > 16)? 'text-g manyword text-dark text-bolder':'text-g text-26 text-dark text-bolder new-exam-name'" v-html="currExam.name"></div>
            <!--eslint-enable-->
             <div class=" text-s text-gray mt-2"><img :src="currExam.isPlay && currExam.isPlay == 'Y' ? 'assets/img/ic_loudspeaker_playing.png':'assets/img/ic_loudspeaker_gray.png'" class="avatar avatar-22 me-2" @click="onPlayVoice(currExam)">{{currExam.phonetic}}</div>
          </div>
          <!-- 選項 Start -->
          <div class="text-center choose_mt"> 
            <div class="position-relative chooise_mb" v-for="(item,itemIndex) of currExam.item" :key="itemIndex"> 
              <!-- eslint-disable vue/no-v-html -->
              <div :class="getItemClass(item)" @click="onItemClick(item)" >
                <div class="d-flex justify-content-center text-mm  text-simbold lh18 " v-html="item.name"></div>
              </div>
              <!--eslint-enable-->
              <img v-if="(item.result && item.result != undefined && item.result != '' && item.result == 'Y')" src="assets/img/img008.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2">
              <img v-if="(item.result && item.result != undefined && item.result != '' && item.result == 'N')" src="assets/img/ic_test_fail.png" class="avatar avatar-check rounded-0 position-absolute topdowncenter ms-2">
            </div>        
          </div>
          <!-- 選項 End -->
          <!-- 所有釋意 Start -->
      <div class="py-2" v-if="isDo">
        <div class="container">
            <h5 class="text-dark text-bold  mt-2 mb-3 text-n">所有釋義</h5>
            <div :class="wordSpeechIndex == 1 ? 'd-flex mb-2':'d-flex'" v-for="(wordSpeech,wordSpeechIndex) of currExam.word_speech" :key="wordSpeechIndex"> 
                <!-- eslint-disable vue/no-v-html -->
                <div class="titlebox-v text-dark  text-smm" v-html="wordSpeech.code"></div>
                <div class="contentbox-v">
                    <div class="text-dark  text-smm lh18" v-html="wordSpeech.name"></div>
                </div>
                <!--eslint-enable-->
            </div>       
        </div>            
        </div>   
          <!-- 所有釋意 End -->    
        
          <!-- 例句 Start -->
      <div class="py-2" v-if="isDo">
        <div class="container">
            <h5 class="text-dark text-bold  mt-2 mb-3 text-n">例句</h5>           
            <div class="d-flex" v-for="(wordSentence,wordSentenceIndex) of currExam.word_sentence" :key="wordSentenceIndex">
                <div class="titlebox-v  mb-2">
                    <img :src="wordSentence.isPlay && wordSentence.isPlay == 'Y' ? 'assets/img/ic_loudspeaker_playing.png':'assets/img/ic_loudspeaker_gray.png'" class="avatar avatar-22 rounded-0 " @click="onPlayVoice(wordSentence)">
                </div>
                <div class="contentbox-v ">
                  <!-- eslint-disable vue/no-v-html -->
                    <div class="text-dark text-simbold text-smm mb-1 mb-2" v-html="wordSentence.name"></div>
                    <h5 class=" text-s text-dark mb-2" v-html="wordSentence.description"></h5>
                    <!--eslint-enable-->
                </div>
            </div>   
        </div>            
        </div>
          <!-- 例句 End -->

    </div>

      <div class="pb-4" v-if="isLast">
        <div class="text-right">
        <button type="button" class="btn btn-lg  btn-success side-btn rounded-start1  pe-0 ps-3 text-center" @click="onCalculate('quiz')"><h5 class="text-white text-smm mb-0 d-flex text-simbold">隨堂練習 <i class="far fa-chevron-right text-white ms-2 text-smm"></i></h5></button>
        </div> 
      </div>     
    </div>
        <div class="text-center  mt-4 ">
        <button type="button" :class="currExam.result != ''? 'btn btn-lg  btn-success rounded-pill w-75  text-s' : 'btn btn-lg  btn-success rounded-pill w-75  text-s disabled' "   @click="onRandomExam">{{isLast?'完成測驗':'下一題'}}</button>
        </div>    
    </div>
        </transition>
</body>

    </div>
</template>


<script>
import Header from '@/components/Header.vue'
import CommonLeftRightAlertFunc from '@/components/CommonLeftRightAlertFunc.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import { config } from "@/include/config"; 

export default {
    name: 'WordReexercise',
    components: {
        Header,
        CommonLeftRightAlertFunc,
    },
    data () {
    return { 
        apiName: 'page_word_reexercise',
        title: "確定要離開嗎",
        content: "中途離開不會計入學習歷程唷！",
        btnNameLeft: "離開",
        btnNameRight: "繼續答題",

        examList: [],
        currentIndex: 0,
        finishExamList: [],
        currExam:{},
        isLast: false,
        isDo: false,
        isShow: false,
        eplid: "",
        huepid: "",
        lsid: "",
        params: {},
        backUrl: "",
        form: {},
        native: "",
        type: "",
        }
    },
    methods: {
      getItemClass(item){
        var res = "";
        if((item.result && item.result != undefined  && item.result == 'Y') || (item.show && item.show != undefined && item.show == 'Y')){
          res = 'badge badge-success rounded-3 choose-btn text-simbold chooise text-wrap';
        }else if(item.result && item.result != undefined  && item.result == 'N'){
          res = 'badge badge-danger rounded-3 choose-btn text-simbold chooise text-wrap';
        }else{
          res = 'badge badge-secondary rounded-3 choose-btn text-simbold chooise text-wrap';
        }
        return res;
      },
      onPlayVoice(item){
        if(item && item != undefined){
          var url = item.audio_url_m3u8;
          if(this.currExam.word_sentence && this.currExam.word_sentence.length > 0){
            for(var i = 0; i < this.currExam.word_sentence.length; i++){
              this.currExam.word_sentence[i].isPlay = "N";
            }
          }
          if(item.lwsid && item.lwsid != "" && item.lwsid != undefined && this.currExam.word_sentence && this.currExam.word_sentence.length > 0){
            for(var i = 0; i < this.currExam.word_sentence.length; i++){
              var sentence = this.currExam.word_sentence[i];
              if(sentence.lwsid == item.lwsid){
                this.currExam.word_sentence[i].isPlay = "Y";
              }else{
                this.currExam.word_sentence[i].isPlay = "N";
              }
            }
          }
          if(item.egid && item.egid != "" && item.egid != undefined){
            this.currExam.isPlay = "Y";
          }else{
            this.currExam.isPlay = "N";
          }
          if(url != ""){
            this.apiName = "video_get_token";
            oAC.getAllRecord(oAC.getApiParam(this), {src:url}).then(
            res => 
            { 
              if(res.get_video_token && res.get_video_token.data && res.get_video_token.data.token && res.get_video_token.data.token != undefined){
                this.$JsBridge.callHandler('nativePlayVoiceUrl', `${url}?token=${res.get_video_token.data.token}`, this.nativePlayVoiceUrlCallback);
              }
              console.log(res);
            }).catch((err)=> {
                oltConsole.log(err);
            });
          }
        }
        var temp = this.currExam;
        this.currExam = {};
        this.currExam = temp;
      },
      nativePlayVoiceUrlCallback(){
        if(this.currExam.word_sentence && this.currExam.word_sentence.length > 0){
          for(var i = 0; i < this.currExam.word_sentence.length; i++){
            this.currExam.word_sentence[i].isPlay = "N";
          }
        }
        this.currExam.isPlay = "N";
        var temp = this.currExam;
        this.currExam = {};
        this.currExam = temp;
      },
      stopPlaySentenceVoiceCallback(data){
        var temp = this.currExam;
        this.currExam = {};
        this.currExam = temp;
      },
      onItemClick(item){
        if(this.currExam && this.currExam.result == ""){
          this.isDo = true;
          var result = false;
          var correctEiid = "";
          if(this.currExam.answer && this.currExam.answer.length > 0){
            for(var i = 0; i < this.currExam.answer.length; i++){
              if(item.eid == this.currExam.answer[i].eid && item.eiid == this.currExam.answer[i].eiid){
                result = true;
              }
              if(item.eid == this.currExam.answer[i].eid){
                correctEiid = this.currExam.answer[i].eiid;
              }
            }
          }
          if(this.currExam.item && this.currExam.item.length > 0){
            for(var i = 0; i < this.currExam.item.length; i++){
              if(item.eid == this.currExam.item[i].eid){
                if(item.eiid == this.currExam.item[i].eiid){
                  this.currExam.item[i].result = result?"Y":"N";
                  this.currExam.result = result?"Y":"N";
                  this.currExam.eiid = item.eiid;
                }
                if(correctEiid == this.currExam.item[i].eiid){
                  this.currExam.item[i].show = "Y";
                }
              }
            }
          }
          if(result){
            if(this.examList && this.examList.length > 0){
              for(var i = 0; i < this.examList.length; i++){
                if(item.eid == this.examList[i].eid){
                  this.finishExamList.push(this.examList[i]);
                }
              }
            }
            this.$JsBridge.callHandler('loadCorrectAudioVue', "", this.loadCorrectAudioVueCallback);
          }else{
            this.$JsBridge.callHandler('loadErrorAudioVue', "", this.loadErrorAudioVueCallback);
          }
          var temp = this.currExam.item;
          this.currExam.item = [];
          this.currExam.item = temp;
        }
        this.isLast = this.finishExamList.length == this.examList.length;
      },
      loadCorrectAudioVueCallback(data){},
      loadErrorAudioVueCallback(data){},
      setOnBack(){
        this.$JsBridge.callHandler('setOnback', '', this.onBack);
      },
      onBack(){    
          this.$refs.commonLeftRightAlertFunc.onShow();
      },
      changeToWebCallback(){},
      getQuery(){
        var query = "";
        if(this.params && this.params != undefined){
          query = Object.keys(this.params)
          .map(key => {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(this.params[key])
            )
          })
          .join('&');
        }
        return query;
      },
      openTeacherExplainCallback(){},
      onExitLeft(){
        // 停止播放音檔
        this.$JsBridge.callHandler('nativeStopVoice', "", this.nativeStopVoiceCallback);
        if(this.backUrl != null && this.backUrl != undefined && this.backUrl != ""){
          if(this.native != null && this.native != undefined && this.native != ""){
            if(this.native.indexOf("teacher_explain") > -1){
                this.$JsBridge.callHandler('openTeacherExplain',JSON.stringify({
                    backUrl: this.backUrl,
                    mode: "vue",
                    type: "back",
                }), this.openTeacherExplainCallback);
            }else{
              this.$router.push(
              {
                  name : "TaskList",
                  params: {
                    lsid: this.lsid,
                  },
              });
            }
          }else{
            if(this.backUrl.includes("TaskList")){
              var name = "";
              var params = {};
              if(this.backUrl && this.backUrl != "" && this.backUrl != undefined && this.backUrl.indexOf("?") > -1){
                  name = this.backUrl.substring(0, this.backUrl.indexOf("?"));
                  var queryStr = this.backUrl.substring(this.backUrl.indexOf("?") + 1, this.backUrl.length);
                  if(queryStr != ""){
                  const urlSearchParams = new URLSearchParams(queryStr);   
                      params = Object.fromEntries(urlSearchParams.entries()); 
                  }
              }
              this.$router.push(
              {
                  name : name,
                  params: params,
              });
            }else{
              this.$JsBridge.callHandler('openWebView', this.backUrl, this.changeToWebCallback);
            }
          }
        }else{
            this.$router.push(
            {
                name : "TaskList",
                params: {
                  lsid: this.lsid,
                },
            });
        }
      },
      onExitRight(){},
      nativeStopVoiceCallback(){},
      onRandomExam(){
        // 停止播放音檔
        this.$JsBridge.callHandler('nativeStopVoice', "", this.nativeStopVoiceCallback);
        // 更新紀錄
        this.updateRecord();
        if(this.isLast){
          // 交卷
          this.onCalculate("tasklist");
        }else{
          this.isShow = false;
          this.currExam = {};
          this.isDo = false;
          if(this.examList != null && this.examList.length > 0){
            var num = (this.getRandom(1, this.examList.length) - 1);
            var exam = this.examList[num];
            if(this.finishExamList != null && this.finishExamList.length > 0){
              var isHave = false;
              for(var i = 0; i < this.finishExamList.length; i++){
                var item = this.finishExamList[i];
                if(item.eid == exam.eid){
                  isHave = true;
                }
              }
              if(!isHave){
                if(exam.result && exam.result == "Y"){
                  this.finishExamList.push(exam);
                }else{
                  this.examList[num].result = "";
                  exam = this.resetItem(num);
                }
                this.currExam = exam;
                setTimeout(this.showExam, 300); 
                this.onPlayVoice(this.currExam);
              }else{
                this.onRandomExam();
              }
            }else{
              this.finishExamList = [];
              if(exam.result && exam.result == "Y"){
                this.finishExamList.push(exam);
              }else{
                this.examList[num].result = "";
                exam = this.resetItem(num);
              }
              this.currExam = exam;
              setTimeout(this.showExam, 300);  
                this.onPlayVoice(this.currExam);
            }
          }
        }
      },
      resetItem(num){
        if(this.examList != null && this.examList.length > num){
          var item = this.examList[num].item;
          if(item && item != undefined && item.length > 0){
            for(var i = 0; i < item.length; i++){
              this.examList[num].item[i].result = "";
              this.examList[num].item[i].show = "";
            }
          }
        }
        return this.examList[num];
      },
      getRandom(min,max){
        return Math.floor(Math.random()*(max-min+1))+min;
      },
      updateRecord(){
        if(this.finishExamList != null && this.finishExamList.length > 0){
          for(var i = 0; i < this.finishExamList.length; i++){
            if(this.finishExamList[i].egid == this.currExam.egid && this.finishExamList[i].eid == this.currExam.eid){
              this.finishExamList[i] = this.currExam;
            }
          }
        }
      },
      showExam(){
        this.isShow = true;
      },
      saveTaskFirstCallback(data){
        this.onJump();
      },
      loadTaskFirstCallback(data){
          if(data != "" && data != "N"){
              try{
                  var json = JSON.parse(data);
                  json[`lsid_${this.lsid}`] = "Y";
                  var saveJson = {
                      key: "isTaskCompleteFirst",
                      object: JSON.stringify(json),
                  };
                  this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
              }catch(err) {
                  console.log(err);
              }
          }else{
                  var json = {};
                  json[`lsid_${this.lsid}`] = "Y";
                  var saveJson = {
                      key: "isTaskCompleteFirst",
                      object: JSON.stringify(json),
                  };
                  this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
          }
      },
      onJump(){
        switch(this.type){
          case "quiz":
            var query = this.getQuery();
            if(config && config.old_exam && config.old_exam=="Y") {
              var vueBack = btoa(`WordReexercise?lsid=${this.lsid}&backUrl=${this.backUrl}${query}`);
              this.$JsBridge.callHandler('openWebView', `/quiz?lsid=${this.lsid}&vue=${vueBack}`, this.changeToWebCallback);
            }else{
              this.$router.push({
                  name: "Quiz",
                  params : {
                      lsid : this.lsid,
                      backUrl: `WordReexercise?lsid=${this.lsid}&backUrl=${this.backUrl}${query}`,
                  }
              });
            }
            break;
          default:
            this.$router.push(
            {
                name : "TaskList",
                params: {
                  lsid: this.lsid,
                },
            });
            break;
        }
      },
      onCalculate(type){
        this.type = type;
        this.form["uid"] = this.$GlobalData.uid;
        this.form["lsid"] = this.lsid;
        this.form["huepid"] = this.huepid;
        this.form["eplid"] = this.eplid;
        this.form["exam_list"] = this.finishExamList;
        this.apiName = 'page_word_reexercise';
        oAC.postRecord(oAC.getApiParam(this)).then((res)=> 
        { 
          if(res.complete_5 && res.complete_5 == "Y" && res.first_complete && res.first_complete == "Y"){  
            this.$JsBridge.callHandler('loadLocalDataVue', JSON.stringify( {key: "isTaskCompleteFirst"}), this.loadTaskFirstCallback);
          }else{
            this.onJump();
          }
        }).catch((err)=> {
            oltConsole.log(err);
        });
      },
    },
    mounted() {
      
    },
    created(){ 
      if(this.$route.params && this.$route.params != undefined){
        if(this.$route.params.lsid && this.$route.params.lsid != undefined){
          this.lsid = this.$route.params.lsid;
        }
        if(this.$route.params.backUrl && this.$route.params.backUrl != undefined){
          this.backUrl = this.$route.params.backUrl;
        }
        if(this.$route.params.native && this.$route.params.native != undefined){
          this.native = this.$route.params.native;
        }
        this.params = this.$route.params;
      }
      if(this.$route.query && this.$route.query != undefined){
        if(this.$route.query.lsid && this.$route.query.lsid != undefined){
          this.lsid = this.$route.query.lsid;
        }
        if(this.$route.query.backUrl && this.$route.query.backUrl != undefined){
          this.backUrl = this.$route.query.backUrl;
        }
        if(this.$route.query.native && this.$route.query.native != undefined){
          this.native = this.$route.query.native;
        }
        this.params = this.$route.query;


        if(this.$route.query.uid && this.$route.query.uid != undefined){
          this.$GlobalData.uid = this.$route.query.uid;
        }
      }
      if (this.$JsBridge.isAndroid) {
        this.setOnBack();
      }

        this.currentIndex = 0;
        this.apiName = 'page_word_reexercise';
        oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid, lsid:this.lsid}).then(
        res => 
            {
              if(res != null && res.exam_list != null && res.exam_list.length > 0){ 
                this.examList = res.exam_list;
                this.huepid = res.exam_list[0].huepid;
                this.eplid = res.exam_list[0].eplid;
                this.onRandomExam();
              }
            }
        )
        .catch((err)=> {
            oltConsole.log(err);
        });
    
    }
};
</script>


<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.disabled {
   background-color: #c9c9c9 !important;
   border-color: #c9c9c9 !important;
}
.dic_gcolor{
  font-weight: 700;
  color: #00b99d;
}
@import '../../../../public/assets/css/admin-customize.css';
</style>


<style scoped>
.text-smm{
  font-size: 1rem !important;
}
.badge-secondary{
  color: #6c757d;
}
@media only screen and (max-width: 1366px) and (orientation: landscape){
  .new-exam-name{
    font-size: 3.6vw !important;
  }
}
@media only screen and (max-width: 1024px){
  .new-exam-name{
    font-size: 5vw !important;
  }
}
@media only screen and (max-width: 768px){
  .new-exam-name{
    font-size: 6vw !important;
  }
}
@media only screen and (max-width: 480px){
  .new-exam-name{
    font-size: 33px !important;
  }
}
@media only screen and (max-width: 320px){
  .new-exam-name{
    font-size: 30px !important;
  }
}
</style>
